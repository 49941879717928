.group-control {
    .action {
        padding: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        font-size: 18px;
        cursor: pointer;

        &:hover {
            border-radius: 2px;
            background-color: var(--dv-icon-hover-background-color);
        }
    }
}
