.dockview-demo {
    .group-control {
        .action {
            padding: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            font-size: 18px;
            cursor: pointer;

            &:hover {
                border-radius: 2px;
                background-color: var(--dv-icon-hover-background-color);
            }
        }
    }

    .data-table {
        table {
            font-size: 11px;
            th {
                padding: 0px 8px;
            }
        }
    }

    button {
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #1c254a;
        color: white;
        border: none;
        cursor: pointer;
        outline: 1px solid #4c65d4;

        &:focus {
            outline: 1px solid #4c65d4 !important;
        }

        &:hover {
            background-color: #222e62;
        }
    }

    input {
        outline: 1px solid #4c65d4;
        border: none;
        margin: 0px;
        height: 25px;

        &:focus {
            outline: 1px solid #4c65d4 !important;
        }
    }

    .action-container {
        display: flex;
        padding: 4px;
        overflow: auto;

        .text-button {
            margin: 0px 4px;
        }

        .button-action {
            margin: 0px 4px;
            // display: flex;

            .selected {
                background-color: #4864dc;
            }
        }

        .button-group {
            button {
                margin-right: 0px;
            }
        }

        .demo-button {
            min-width: 50px;
            padding: 0px 2px;
            border-radius: 0px;
            display: flex;
            flex-grow: 1;
            align-items: center;
            outline: 1px solid #4c65d4;
        }

        .demo-icon-button {
            outline: 1px solid #4c65d4;
            flex-grow: 1;
            display: flex;
            align-items: center;
            border-radius: 0px;
            padding: 0px 4px;
            border: none;
            cursor: pointer;

            &:disabled {
                color: gray;
                cursor: help;
            }

            span {
                font-size: 16px;
            }
        }
    }
}
