.my-custom-tab {
    padding: 0px 8px;
    width: 100%;
    display: flex;
    height: 100%;
    align-items: center;
    background-color: var(--dv-tabs-and-actions-container-background-color);

    .my-custom-tab-icon {
        font-size: 16px;

        &:hover {
            border-radius: 2px;
            background-color: var(--dv-icon-hover-background-color);
        }
    }
}
